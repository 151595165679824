/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

const parseOldDirective = function($expression) {
  const argRE = /^[^\{\?]+$|^'[^']*'$|^"[^"]*"$/;
  const filterTokenRE = /[^\s'"]+|'[^']*'|"[^"]*"/g;
  const reservedArgRE = /^in$|^-?\d+/;

  const stripQuotes = function(str) {
    const a = str.charCodeAt(0);
    const b = str.charCodeAt(str.length - 1);
    if ((a === b) && ((a === 0x22) || (a === 0x27))) { return str.slice(1, -1); } else { return false; }
  };

  let str = undefined;
  let c = undefined;
  let i = undefined;
  let l = undefined;
  let inSingle = undefined;
  let inDouble = undefined;
  let curly = undefined;
  let square = undefined;
  let paren = undefined;
  let begin = undefined;
  let argIndex = undefined;
  let dirs = undefined;
  let dir = undefined;
  let lastFilterIndex = undefined;
  let arg = undefined;

  const processFilterArg = function(arg) {
    const stripped = reservedArgRE.test(arg) ? arg : stripQuotes(arg);
    const dynamic = stripped === false;
    return {
      value: dynamic ? arg : stripped,
      dynamic
    };
  };

  const pushFilter = function() {
    const exp = str.slice(lastFilterIndex, i).trim();
    let filter = undefined;
    if (exp) {
      filter = {};
      const tokens = exp.match(filterTokenRE);
      filter.name = tokens[0];
      if (tokens.length > 1) {
        filter.args = tokens.slice(1).map(processFilterArg);
      }
    }
    if (filter) {
      (dir.filters = dir.filters || []).push(filter);
    }
    lastFilterIndex = i + 1;
  };

  const pushDir = function() {
    dir.raw = str.slice(begin, i).trim();
    if (dir.expression === undefined) {
      dir.expression = str.slice(argIndex, i).trim();
    } else if (lastFilterIndex !== begin) {
      pushFilter();
    }
    if ((i === 0) || dir.expression) {
      dirs.push(dir);
    }
  };

  const parseDirective = function(s) {
    str = s;
    inSingle = (inDouble = false);
    curly = (square = (paren = (begin = (argIndex = 0))));
    lastFilterIndex = 0;
    dirs = [];
    dir = {};
    arg = null;
    i = 0;
    l = str.length;
    while (i < l) {
      c = str.charCodeAt(i);
      if (inSingle) {
        if (c === 0x27) {
          inSingle = !inSingle;
        }
      } else if (inDouble) {
        if (c === 0x22) {
          inDouble = !inDouble;
        }
      } else if ((c === 0x2C) && !paren && !curly && !square) {
        pushDir();
        dir = {};
        begin = (argIndex = (lastFilterIndex = i + 1));
      } else if ((c === 0x3A) && !dir.expression && !dir.arg) {
        arg = str.slice(begin, i).trim();
        if (argRE.test(arg)) {
          argIndex = i + 1;
          dir.arg = stripQuotes(arg) || arg;
        }
      } else if ((c === 0x7C) && (str.charCodeAt(i + 1) !== 0x7C) && (str.charCodeAt(i - 1) !== 0x7C)) {
        if (dir.expression === undefined) {
          lastFilterIndex = i + 1;
          dir.expression = str.slice(argIndex, i).trim();
        } else {
          pushFilter();
        }
      } else {
        switch (c) {
          case 0x22:
            inDouble = true;
            break;
          case 0x27:
            inSingle = true;
            break;
          case 0x28:
            paren++;
            break;
          case 0x29:
            paren--;
            break;
          case 0x5B:
            square++;
            break;
          case 0x5D:
            square--;
            break;
          case 0x7B:
            curly++;
            break;
          case 0x7D:
            curly--;
            break;
        }
      }
      // }
      i++;
    }
    if ((i === 0) || (begin !== i)) {
      pushDir();
    }
    return dirs;
  };

  return parseDirective($expression);
};


export const ValidationRules = {
  install (app, _options) {
    app.config.globalProperties.$sOptions = {validators: {}}

    const files = ['confirmation', 'email', 'equalTo',
    'function', 'max', 'maxLength', 'min', 'minLength',
    'numeric', 'pattern', 'required']

    files.forEach(name=>{
      import(`./../validations/${name}.js`).then(binary=>{
        app.config.globalProperties.$sOptions.validators[name] = binary.default
      })
    })
  },
}

const runValidationsAgainstValue = function(field, expression="", vm, value) {
  const validations = parseOldDirective(expression);
  try {
    const attrValid = !validations.every(function(item) {
      const validation = item.arg || item.expression
      const result = vm.$sOptions.validators[validation](field, value, item, vm);
      vm.$set1(`validation.${field}.${validation}`, !result);
      return result;
    })
    return vm.$set1(`validation.${field}.invalid`, attrValid);
  } catch (error) {
    return console.info(error);
  }
};

const listener = (field, expression="", vm) => value => runValidationsAgainstValue(field, expression, vm, value);

const targetListener = (field, expression="", vm) => value => runValidationsAgainstValue(field, expression, vm, vm.$get1(field));

const watchTargets = (field, expression="", vm) => parseOldDirective(expression).forEach(function(item) {
  let key;
  const validation = item.arg || item.expression;
  if (key = vm.$sOptions.validators[validation].watchTarget) {
    vm.$watch(item[key], targetListener(field, item.raw, vm), { deep: true, immediate: false});
    if (vm.$sOptions.validators[validation].watchOrigin) {
      return vm.$watch(field, targetListener(field, item.raw, vm), { deep: true, immediate: false});
    }
  }
});


let ShapeFlags

;(function(ShapeFlags) {
  ShapeFlags[(ShapeFlags["ELEMENT"] = 1)] = "ELEMENT"
  ShapeFlags[(ShapeFlags["FUNCTIONAL_COMPONENT"] = 2)] = "FUNCTIONAL_COMPONENT"
  ShapeFlags[(ShapeFlags["STATEFUL_COMPONENT"] = 4)] = "STATEFUL_COMPONENT"
  ShapeFlags[(ShapeFlags["TEXT_CHILDREN"] = 8)] = "TEXT_CHILDREN"
  ShapeFlags[(ShapeFlags["ARRAY_CHILDREN"] = 16)] = "ARRAY_CHILDREN"
  ShapeFlags[(ShapeFlags["SLOTS_CHILDREN"] = 32)] = "SLOTS_CHILDREN"
  ShapeFlags[(ShapeFlags["TELEPORT"] = 64)] = "TELEPORT"
  ShapeFlags[(ShapeFlags["SUSPENSE"] = 128)] = "SUSPENSE"
  ShapeFlags[(ShapeFlags["COMPONENT_SHOULD_KEEP_ALIVE"] = 256)] =
    "COMPONENT_SHOULD_KEEP_ALIVE"
  ShapeFlags[(ShapeFlags["COMPONENT_KEPT_ALIVE"] = 512)] =
    "COMPONENT_KEPT_ALIVE"
  ShapeFlags[
    (ShapeFlags["COMPONENT"] =
      ShapeFlags.STATEFUL_COMPONENT | ShapeFlags.FUNCTIONAL_COMPONENT)
    ] = "COMPONENT"
})(ShapeFlags || (ShapeFlags = {}))

function getCompatChildren(renderInstance) {
  const instance = renderInstance.$
  const root = instance.subTree
  const children = []
  if (root) {
    walk(root, children)
  }
  return children
}

function walk(vnode, children) {
  if (vnode.component) {
    children.push(vnode.component.proxy)
  } else if (vnode.shapeFlag & ShapeFlags.ARRAY_CHILDREN) {
    const vnodes = vnode.children
    for (let i = 0; i < vnodes.length; i++) {
      walk(vnodes[i], children)
    }
  }
}

const run_deep_validation = function(instance, args) {
  const child_validations = (!getCompatChildren(instance) || (function () {
    const list = getCompatChildren(instance).map(child => run_deep_validation(child, args));
    return !list.length || (JSON.stringify(list.uniq()) === JSON.stringify([true]));
  })());

  const self_validations = (!instance.$validate || instance.$validate.apply(instance, args));
  return child_validations && self_validations;
};

const collection = vm => vm.$options.validations || {};

export const ValidationMixin = ({
  data: ()=>( {
    validation: {}
  }),

  methods: {
    $get1(full_key) {
      const keys = full_key.split(".");
      const last = keys.pop();
      let object = this;
      for (let key of keys) {
        var match_result;
        if (match_result = key.match(/^([^\[]*)\[([^\]]*)\]$/)) {
          object = object?.[match_result[1]];
          object = object?.[match_result[2]];
        } else {
          object = object?.[key];
        }
      }
      return object?.[last];
    },
    $set1(path, value) {
      let schema = this;  // a moving reference to internal objects within obj
      let pList = path.split('.');
      let len = pList.length;
      for(let i = 0; i < len-1; i++) {
        const elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
      }

      schema[pList[len-1]] = value;
    },

    $deep_validate() { // validates self and all children and childrens children
      const args = arguments;
      return run_deep_validation(this, args);
    },

    $validate() {
      const keys = arguments.length ? [].slice.call(arguments) : Object.keys(collection(this));
      const result = keys.map(key => {
        if (collection(this)[key]) {
          listener(key, collection(this)[key], this).call(this, this.$get1(key));
          return !this.$get1(`validation.${key}.invalid`);
        } else { return true; }
      }).indexOf(false) === -1;
      if (!result) {
        if (import.meta.env.MODE === 'development') {
          console.error('validation failed at:',{url: window.location, errors: this.validation, options: this.$options});
        }
      }

      // scroll to the first error place
      this.$nextTick().then(() => {
        const el= document.querySelector(".formError")
        if (el) { return window.scroll(0, el.offsetTop); } });
      return result;
    },

    isInvalid(key) {
      return this.$get1(`validation.${key}.invalid`);
    }
  },

  mounted() {
    return this.$nextTick().then(_ => {
      return this.$nextTick().then(() => {
        const object = collection(this);
        for (var field in object) {
          var expression = object[field];
          watchTargets(field, expression, this);
          //@$watch(field, listener(field, expression, @), { deep: true, immediate: false})
          // some weird hack. for some reason at least on login page watcher was fired even when there were no changes
          // to a model field
          this.$watch(field, (newVal, oldVal) => {
              if (newVal !== oldVal) {
                return listener(field, expression, this);
              }
            }
            , {deep: true, immediate: false});
        }
      });
    });
  }
});
