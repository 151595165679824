import { defineAsyncComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

const Sidebar = defineAsyncComponent(()=> import('../components/sidebar.vue'))
const Header = defineAsyncComponent(()=> import('../components/header.vue'))

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `../components/${view}.vue`)
}

const defaultComponents  = (main)=>{
  let m = main
  if (typeof main === 'string') m = loadView(main)
  return (
    {
      default: m,
      sidebar: Sidebar,
      header: Header
    }
  )
}

const emptyPageComponents = (main)=>{
  let m = main
  if (typeof main === 'string') m = loadView(main)
  return (
    {
      default: m,
    }
  )
}

const fullPageComponents = (main)=>{
  let m = main
  if (typeof main === 'string') m = loadView(main)
  return (
    {
      default: m,
      header: Header
    }
  )
}

const routes = [
  { path: '/', components: defaultComponents('statistics'), name: 'statistics' },
  { path: '/onboarding', components: defaultComponents('onboarding'), name: 'onboarding' },
  { path: '/authenticate',               components: emptyPageComponents('authenticate'), name: 'authenticate' },
  { path: '/authenticate/:invite_token', components: emptyPageComponents('authenticate'), name: 'authenticate-invite' },
  { path: '/passwords',           components: emptyPageComponents('passwords/forgot'), name: 'passwords-forgot' },
  { path: '/passwords/reset/:id', components: emptyPageComponents('passwords/change'), name: 'passwords-change' },
  { path: '/invite/:id',       components: emptyPageComponents('invites/register'), name: 'invites-register' },
  { path: '/invite/:id/',      components: emptyPageComponents('invites/register'), name: 'invites-register2' },
  { path: '/invite/:id/login', components: emptyPageComponents('invites/login'),    name: 'invites-login' },
  { path: '/profile', components: defaultComponents('profile'), name: 'profile' },
  { path: '/stores', components: fullPageComponents('stores'), name: 'stores' },
  { path: '/personal-details', components: fullPageComponents('personal-details'), name: 'personal-details' },
  { path: '/plans', components: defaultComponents('plans/index'), name: 'billing'},
  { path: '/plans/card', components: fullPageComponents('plans/card'), name: 'billing-card'},
  { path: '/plans/cancel', components: defaultComponents('plans/cancel'), name: 'billing-cancel'},
  { path: '/collections/:id/products', components:defaultComponents('collections/products') },
  { path: '/media_library', components: defaultComponents('media_library/index')},
  { path: '/oauth/authorize', components: emptyPageComponents('oauth')},
  { path: '/themes',     components: defaultComponents('themes/index')},
  { path: '/themes/:id', components: defaultComponents('themes/index')},
  { path: '/themes/:theme_id/sources',                components: defaultComponents('themes/sources/index')},
  { path: '/emails/:theme_id/sources',                components: defaultComponents('themes/sources/index'), meta: {type: 'email'}},
  { path: '/themes/:theme_id/builder',                components: {default: loadView('themes/builder/index'), sidebar: loadView('themes/builder/sidebar')}},
  { path: '/themes/:theme_id/builder/:page',          components: {default: loadView('themes/builder/index'), sidebar: loadView('themes/builder/sidebar')}},
  { path: '/themes/:theme_id/builder/:page/:section', components: {default: loadView('themes/builder/index'), sidebar: loadView('themes/builder/sidebar')}},
  { path: '/settings', components: defaultComponents('settings/index')},
  { path: '/checkout', components: defaultComponents('checkout/index')},
  { path: '/inventory-settings', components: defaultComponents('inventory/settings/index')},
  { path: '/review-settings', components: defaultComponents('settings/review-settings')},
  { path: '/users/:id/edit-invite', components: defaultComponents('users/edit_invite')},
  { path: '/integrations', components: defaultComponents('integrations/index')},
  { path: '/integrations/data-exports', components: defaultComponents('integrations/data_exports')},
  { path: '/emails', components: defaultComponents('emails/index')},
  { path: '/emails/settings', components: defaultComponents('emails/settings')},
  { path: '/emails/:id/old', components: defaultComponents('emails/edit')},
  { path: '/emails/:theme_id/builder', components: {default: loadView('themes/builder/index'), sidebar: loadView('themes/builder/sidebar')}},
  { path: '/warehouses', components: defaultComponents('warehouses/index')},
  { path: '/payments', components: defaultComponents('payments/index')},
  { path: '/payments/providers/:provider_id/methods/new', components: defaultComponents('payments/payment_method')},
  { path: '/payments/providers/:provider_id/methods/:id/edit', components: defaultComponents('payments/payment_method')},
  { path: '/search', components: defaultComponents('search/index')},
  { path: '/webhooks', components: defaultComponents('webhooks/index')},
  { path: '/inventory', components: defaultComponents('inventory/index')},
  { path: '/inventory/:product_id/variants', components: defaultComponents('inventory/inventory/variants')},
  { path: '/inventory/:product_id/:stock_entry_id/items', components: defaultComponents('inventory/inventory/items')},
  { path: '/inventory/:product_id/:stock_entry_id/items/:id/history', components: defaultComponents('inventory/inventory/item_history')},
  { path: '/inventory/procurements', components: defaultComponents('inventory/procurements/index')},
  { path: '/inventory/procurements/:id/edit', components: defaultComponents('inventory/procurements/edit')},
  { path: '/inventory/transfers',             components: defaultComponents('inventory/transfers/index')},
  { path: '/inventory/transfers/:id/edit',    components: defaultComponents('inventory/transfers/edit')},
  { path: '/inventory/transfers/:id/receive', components: defaultComponents('inventory/transfers/receive')},
  { path: '/inventory/stocktaking',           components: defaultComponents('inventory/stocktaking/index')},
  { path: '/inventory/stocktaking/:id/edit',  components: defaultComponents('inventory/stocktaking/edit')},
  { path: '/inventory/stocktaking/:id/scan',  components: defaultComponents('inventory/stocktaking/scan')},
  { path: '/inventory/lifecycle',  components: defaultComponents('inventory/lifecycle/index')},
  // { path: '/inventory/lifecycle/new',  components: defaultComponents('inventory/lifecycle/new')},
  { path: '/transfers',             components: defaultComponents('stock_transfers/index')},
  { path: '/transfers/new',         components: defaultComponents('stock_transfers/new')},
  { path: '/transfers/:id/edit',    components: defaultComponents('stock_transfers/new')},
  { path: '/transfers/:id/send',    components: defaultComponents('stock_transfers/confirm'), name: "TransfersSend"},
  { path: '/transfers/:id/confirm', components: defaultComponents('stock_transfers/confirm'), name: "TransfersConfirm"},
  { path: '/transfers/:id',         components: defaultComponents('stock_transfers/show')},
  { path: '/order-returns',             components: defaultComponents('order-returns/index')},
  { path: '/order-returns/new',         components: defaultComponents('order-returns/new')},
  { path: '/order-returns/:id/edit',    components: defaultComponents('order-returns/new')},
  { path: '/order-returns/:id/send',    components: defaultComponents('order-returns/receive'), name: "OrderReturnsSend"},
  { path: '/order-returns/:id/receive', components: defaultComponents('order-returns/receive')},
  { path: '/order-returns/:id',         components: defaultComponents('order-returns/show')},
  { path: '/orders/packages',                  components: defaultComponents('orders/packages/index')},
  { path: '/recurring-orders',                  components: defaultComponents('orders-recurring/index')},
  { path: '/recurring-orders/:id/show',         components: defaultComponents('orders-recurring/show')},
  { path: '/recurring-orders/:id/products',     components: defaultComponents('orders-recurring/products')},
  { path: '/recurring-orders/:id/transactions', components: defaultComponents('orders-recurring/transactions')},
  { path: '/returns',                   components: defaultComponents('returns/index')},
  // { path: '/returns/new',               components: defaultComponents('returns/create')},
  { path: '/returns/show',              components: defaultComponents('returns/show')},
  { path: '/returns/receive',           components: defaultComponents('returns/receive')},
  { path: '/oauth/apps',          components: defaultComponents('oauth-apps/index')},
  { path: '/oauth/apps/new',      components: defaultComponents('oauth-apps/new')},
  { path: '/oauth/apps/:id/edit', components: defaultComponents('oauth-apps/new')},
  { path: '/gift-cards', components: defaultComponents('gift_cards/index')},
  { path: '/reports', components: defaultComponents('reports/index')},
  { path: '/reports/advanced/generate', components: defaultComponents('reports/generate')},
  { path: '/reports/advanced/configs', components: defaultComponents('reports/advanced/configs/index')},
  { path: '/reports/advanced/configs/new', components: defaultComponents('reports/advanced/configs/new')},
  { path: '/reports/advanced/configs/:id/edit', components: defaultComponents('reports/advanced/configs/new')},
  { path: '/reports/advanced/generated', components: defaultComponents('reports/advanced/generated/index')},
  { path: '/reports/advanced/generated/new', components: defaultComponents('reports/advanced/generated/new')},
  { path: '/reports/advanced/generated/:id/edit', components: defaultComponents('reports/advanced/generated/new')},
  { path: '/custom-fields',          components: defaultComponents('custom_fields/index')},
  { path: '/custom-fields/:id/edit', components: defaultComponents('custom_fields/edit')},
  { path: '/customer-subscription-plans', components: defaultComponents('customer_subscription_plans/index')},
  { path: '/customer-subscription-plans/new', components: defaultComponents('customer_subscription_plans/edit')},
  { path: '/customer-subscription-plans/:id/edit', components: defaultComponents('customer_subscription_plans/edit')},
  { path: '/customer-subscriptions', components: defaultComponents('customer_subscriptions/index')},
  { path: '/customer-subscription-invoices', components: defaultComponents('customer_subscription_invoices/index')},
  { path: '/customer-subscription-invoices/:id/edit', components: defaultComponents('customer_subscription_invoices/edit')},
  { path: '/abandoned-carts', components: defaultComponents('abandoned_carts/index') },
  { path: '/users/roles', components: defaultComponents('users/roles/index') },
  { path: '/auth/failure', components: emptyPageComponents('omniauth/failed'), name: 'omniauth-failed' },
  { path: '/auth/:provider/callback', components: defaultComponents('omniauth/callback') },
  { path: '/import-export', components: defaultComponents('imports/index')},
  { path: '/guides/design', components: defaultComponents('guides/design/index')}
]
const resources = (r, path, index, form)=>{
  r.push({path: `/${path}`, components: defaultComponents(index), name: `${path}-index`})
  r.push({path: `/${path}/new`, components: defaultComponents(form), name: `${path}-new`})
  r.push({path: `/${path}/:id/edit`, components: defaultComponents(form), name: `${path}-edit`})
}
resources(routes, "vendors", 'vendors/index', 'vendors/form')
resources(routes, "reviews", 'reviews/index')
resources(routes, "product-types", 'product_types/index','product_types/form')
resources(routes, "categories", 'categories/index','categories/new')
resources(routes, "collections", 'collections/index','collections/new')
resources(routes, "products", 'products/index','products/new')
routes.push({ path: '/products/:id/edit/attributes', components: defaultComponents('products/attributes'), name: "products-attributes"})
routes.push({ path: '/products/:id/edit/related-products', components: defaultComponents('products/related_products')})
routes.push({ path: '/orders/print-invoices/:id', components: fullPageComponents('orders/print_invoice')})
routes.push({ path: '/order-drafts', components: defaultComponents('orders/drafts')})
resources(routes, "orders", 'orders/index','orders/edit')
resources(routes, "customers", 'customers/index','customers/edit')
resources(routes, "customer-groups", 'customer_groups/index','customer_groups/edit')
resources(routes, "customer-credits", 'customer_credits/index','customer_credits/edit')
resources(routes, "discounts", 'discounts/index','discounts/edit')
routes.push({ path: '/discounts/:id', components: defaultComponents('discounts/show')})
resources(routes, "menus", 'menus/index','menus/edit')
resources(routes, "pages", 'pages/index','pages/edit')
resources(routes, "blog-categories", 'blog_categories/index','blog_categories/edit')
resources(routes, "blog-posts", 'blog_posts/index','blog_posts/edit')
resources(routes, "users", 'users/index','users/edit')
resources(routes, "domains", 'domains/index','domains/edit')
resources(routes, "taxes", 'taxes/index','taxes/index')
resources(routes, "translations", 'translations/index','translations/edit')
routes.push({ path: '/translations/:id/:type', components: defaultComponents('translations/translations-entity-list')})
routes.push({ path: '/translations/:id/:type/:entity', components: defaultComponents('translations/translations-entity')})
routes.push({ path: '/shippings', components: defaultComponents('shippings/index')})
routes.push({ path: '/shippings/regions', components: defaultComponents('shippings/regions')})
routes.push({ path: '/shippings/rates_general', components: defaultComponents('shippings/rates_general')})
routes.push({ path: '/shippings/rates_custom/new', components: defaultComponents('shippings/rates_custom')})
routes.push({ path: '/shippings/rates_custom/:id', components: defaultComponents('shippings/rates_custom')})
routes.push({ path: '/shippings/providers/:name_key', components: defaultComponents('shippings/provider')})
routes.push({ path: '/shippings/local-deliveries/:id', components: defaultComponents('shippings/local_delivery/delivery')})
routes.push({ path: '/shippings/local-deliveries/:warehouse_id/schedule/:id', components: defaultComponents('shippings/local_delivery/schedule')})
routes.push({ path: '/shippings/local-deliveries/:warehouse_id/zone/new', components: defaultComponents('shippings/local_delivery/zone')})
routes.push({ path: '/shippings/local-deliveries/:warehouse_id/zone/:id', components: defaultComponents('shippings/local_delivery/zone')})
resources(routes, 'suppliers', 'suppliers/index', 'suppliers/new')

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    if (savedPosition == null){
      window.scrollTo(0, 0);
    }
  },
})
function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if(!hasQueryParams(to) && hasQueryParams(from) && to.path === from.path){
    next({name: to.name, query: from.query});
  } else {
    next()
  }
})
