// comment in if we have problems with old browsers
//import "core-js";
//import "regenerator-runtime/runtime";
// require.context('@/admin/images', true) // switch off for now

window.global || (window.global= window) // fix vite + webpack plugins
import { createApp, defineAsyncComponent } from 'vue'
import VueAxios from 'vue-axios'
import DOMPurify from 'dompurify';
import {router} from "@/admin/application/routes.js";
import {store} from "@/admin/application/store.js";
import {axios} from "@/admin/application/axios.js";
import Translation from '@/admin/mixins/translations.js'
import base_js from "@/admin/mixins/base_js";
import 'balloon-css';

// import Sugar from "sugar"
// Sugar.extend()

import { ValidationMixin, ValidationRules } from '@/admin/mixins/validations.js'
import '@/admin/libs/i18n_main.js'
import '@/admin/libs/read_file'
import '@/admin/libs/array'
import '@/admin/libs/string'
import '@/admin/libs/polyfill-fetch.js'

import App from '@/admin/app.vue';

const header = defineAsyncComponent(()=> import('@/admin/components/helpers/collection-header.vue'))
const pagination = defineAsyncComponent(()=> import('@/admin/components/helpers/pagination.vue'))
const permalink = defineAsyncComponent(()=> import('@/admin/components/helpers/permalink.vue'))
const redactor = defineAsyncComponent(()=> import('@/admin/components/helpers/redactor.vue'))
const helpers_label = defineAsyncComponent(()=> import('@/admin/components/helpers/label.vue'))
const select_autocomplete = defineAsyncComponent(()=> import('@/admin/components/helpers/select-autocomplete.vue'))
const helpers_val_msg = defineAsyncComponent(()=> import('@/admin/components/helpers/validation-message.vue'))
const helpers_dropdown = defineAsyncComponent(()=> import('@/admin/components/helpers/dropdown.vue'))
const toggle_v2 = defineAsyncComponent(()=> import('@/admin/components/helpers/toggle-v2.vue'))
const v3_main  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-main.vue'))
const v3_header  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-header.vue'))
const v3_container  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-container.vue'))
const v3_modal  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-modal.vue'))
const v3_card  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-card.vue'))
const v3_icon  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-icon.vue'))
const v3_badge  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-badge.vue'))
const v3_button  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-button.vue'))
const v3_buttons  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-buttons.vue'))
const v3_form_input  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-form-input.vue'))
const v3_form_select  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-form-select.vue'))
const v3_form_select_item  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-form-select-item.vue'))
const v3_form_textarea  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-form-textarea.vue'))
const v3_form_checkbox  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-form-checkbox.vue'))
const v3_form_checkbox_item = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-form-checkbox-item.vue'))
const v3_form_radio_group  = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-form-radio-group.vue'))
const v3_form_radio_item = defineAsyncComponent(()=> import('@/admin/components/helpers/v3-form-radio-item.vue'))
import FlagIcon from 'vue-flag-icon'
import { createConsumer } from "@rails/actioncable"


const Validation = {
  install (app, _options) {
    // config from sanitize-html/vue-3-sanitize
    const ALLOWED_ATTRS = {
      allowedTags: [
        'img', 'blockquote', 'strong', 'pre',
        'h1', 'h2', 'h3', 'h4', 'h5', 'p',
        'em', 'u', 'ul', 'ol', 'li', 'br',
        'hr', 'a',
      ],
      allowedAttributes: {
        a: ['href', 'target'],
        p: ['style'],
        img: ['src', 'data-image', 'srcset', 'alt', 'title', 'width', 'height']
      },
    }
    const ALLOWED_TAGS = ALLOWED_ATTRS.allowedTags
    const ALLOWED_ATTR = Object.entries(ALLOWED_ATTRS.allowedAttributes).flatMap(a=>a[1])


    app.config.globalProperties.set_validation2 = (component, klass, error) => {
      const js = error.response.data
      if (js.errors){
        component.validation || this.$set(component, 'validation', {})
        component.$set(component.validation, klass, {})

        for (const type in js.errors.details) {
          const errs = js.errors.details[type]
          component.validation[klass][type] || component.$set(component.validation[klass], type, {})
          component.$set(component.validation[klass][type], 'invalid', true)
          if (errs[0]) component.$set(component.validation[klass][type], errs[0].error, true)
        }

        for (const type in js.errors.data){
          const errs = js.errors.data[type]
          component.validation[klass][type] || component.$set(component.validation[klass], type, {})
          component.$set(component.validation[klass][type], 'invalid', true)
          for (const err of errs) component.$set(component.validation[klass][type], err, true)
        }

        for (const errSource in js.errors) {
          if (errSource === "data" || errSource === "details") continue
          for (const type in js.errors[errSource]) {
            const errs = js.errors[errSource][type]
            component.validation[klass][type] || component.$set(component.validation[klass], type, {})
            component.$set(component.validation[klass][type], 'invalid', true)
            if (errs[0]) component.$set(component.validation[klass][type], errs[0].error, true)
            component.validation[klass].error_messages || component.$set(component.validation[klass], 'error_messages', {})
            component.$set(component.validation[klass].error_messages, type, errs)
          }
        }

        component.$nextTick(_=>{ document.querySelector(".formError")?.scrollIntoView})
      }
    }
    app.config.globalProperties.$cableConsumer = createConsumer();
    app.config.globalProperties.$clone = (el)=> JSON.parse(JSON.stringify(el))
    app.config.globalProperties.$sanitize = (str) => DOMPurify.sanitize(str, {ALLOWED_TAGS,ALLOWED_ATTR})
  },
}

import * as Sentry from "@sentry/vue";
window.Sentry = Sentry


document.addEventListener('DOMContentLoaded', () => {
  const app = createApp(App)
  if (process.env.SENTRY_ADMIN_JS){
    Sentry.init({ app, dsn: process.env.SENTRY_ADMIN_JS, environment: process.env.RAILS_ENV });
  }
  app.use(Validation)
  app.mixin(base_js)
  app.mixin(Translation)
  app.use(ValidationRules)
  app.mixin(ValidationMixin)

  app.use(VueAxios, axios)
  app.use(FlagIcon)
  app.component('redactor', redactor)
  app.component('select-autocomplete', select_autocomplete)
  app.component('pagination', pagination)
  app.component('permalink', permalink)
  app.component('collection-header', header)
  app.component('helpers-label', helpers_label)
  app.component('helpers-validation-message', helpers_val_msg)
  app.component('helpers-dropdown', helpers_dropdown)
  app.component('toggle-v2', toggle_v2)
  app.component('shoperb-main', v3_main)
  app.component('shoperb-header', v3_header)
  app.component('shoperb-container', v3_container)
  app.component('shoperb-modal', v3_modal)
  app.component('shoperb-card', v3_card)
  app.component('shoperb-icon', v3_icon)
  app.component('shoperb-badge', v3_badge)
  app.component('shoperb-button', v3_button)
  app.component('shoperb-buttons', v3_buttons)
  app.component('shoperb-form-input', v3_form_input)
  app.component('shoperb-form-select', v3_form_select)
  app.component('shoperb-form-select-item', v3_form_select_item)
  app.component('shoperb-form-textarea', v3_form_textarea)
  app.component('shoperb-form-checkbox', v3_form_checkbox)
  app.component('shoperb-form-checkbox-item', v3_form_checkbox_item)
  app.component('shoperb-form-radio-group', v3_form_radio_group)
  app.component('shoperb-form-radio-item', v3_form_radio_item)
  app.use(router)
  app.use(store)
  app.mount('#admin-container')
  window.vue = app

});
