window.read_file = (function() {
  class LoadedImage {
    constructor(options = {}) {
      this.url = options.url
      this.name = options.name
      this.type = options.type
      this.size = options.size
      this.width = options.width
      this.height = options.height
    }
  }

  if(window.FileReader){
    const filter = /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump)$/i
    return function (file, cb, target, options={}){
      if (!options.reader) options.reader = 'readAsDataURL'
      const reader = new window.FileReader()
      reader.onload = (e)=>{
        if (typeof cb === "function") {
          cb(new LoadedImage({
            url: e.target.result,
            type: file.type,
            name: file.name
          }), options.index);
        }
        if (target && filter.test(file.type)) target.src = e.target.result

      }
      return reader[options.reader](file)
    }
  }else {
    console.error("read_file not supported")
  }

})()
